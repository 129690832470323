$publicPath:'';

@import "./../../assets/css/global-variable";

.g-box {
  overflow-x: hidden;
  height: 100%;
  background: #ffffff;
}
.home-box {
  width: 100%;
  height: 64px;
  background: #206e55;
  position: relative;

  img {
    width: 100%;
  }
}

#myCanvas-wrap {
  width: 100%;
  // min-width: 1600px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: auto;
  background: #f7f8f7;
}

#myCanvas {
  display: block;
  position: relative;
  width: 1440px;
  // width: 90%;
  margin: 0 auto;
  // height: 900px;
  // position: absolute;
  // background: url("~assets/img/home/bg.jpg") no-repeat 50% 0;
  background-size: 1440px;
  // background-size: 90%;
  // &:before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: -1000px;
  //   width: 1000px;
  //   height: 475px;
  // background: #0aa667;
  // }
  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   right: -1000px;
  //   width: 1000px;
  //   height: 475px;
  // background: #0aa667;
  // }
}

.lang-item {
  border: solid 1px #ffffff;
  border-radius: 4px;
  padding: 5px;
}

.market-main {
  background-color: #f7f8f7;
  padding: 24px 24px 0;
}

.chart-select {
  width: 150px;
  display: inline-block;

  &:last-child {
    margin-left: 24px;
  }
}

.chart-panel {
  padding: 24px;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 24px;
}

.chart-height {
  height: 353px;
}

.chart-tt {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;

  &-lt {
    width: 4px;
    height: 22px;
    background: #09a666;
    opacity: 1;
    border-radius: 10px;
  }

  &-rt {
    margin-left: 8px;
    opacity: 0.85;
  }

  &.top {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  &.bt {
    margin-bottom: 16px;
  }
}

.chart {
  height: 100%;
}

.btn-sub {
  width: 88px;
  height: 32px;
  background: #09a666;
  opacity: 1;
  border-radius: 3px;
  color: white;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  margin: 16px 0 24px;
  cursor: pointer;
}
.btn-release{
  width: 88px;
  height: 32px;
  background: #09a666;
  opacity: 1;
  border-radius: 3px;
  color: white;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  margin: 0 auto 0;
  cursor: pointer;
}
.btn-flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tb-btn-mn {
  display: flex;
  justify-content: space-around;
  align-items: center;

  span {
    color: #e9e9e9;
  }
}

.tb-tt {
  width: 84px;
  height: 36px;
  background: #ffffff;
  border-top: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  opacity: 1;
  border-radius: 0px 2px 0px 0px;
  font-size: 14px;
  color: #09a666;
  text-align: center;
  line-height: 36px;

  &.price {
    color: #575757;
    cursor: pointer;

    &:last-child {
      border-left: none;
    }

    &.active {
      color: #09a666;
      border-top: 2px solid #09a666;
    }
  }
}

.tb-btn {
  font-size: 14px;

  &.green {
    color: #09a666;
  }

  &.red {
    color: #ff4d4f;
  }
}
